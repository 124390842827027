import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from 'axios';
import { baseUrl } from '../Component/utils/config.js';


const DistrictList = () => {
    let { districtId } = useParams();
    const [legislatorData, setLegislatorData] = useState([]);
    const [MunicipalName, setMunicipalName] = useState('');
    const [MunicipalID, setMunicipalID] = useState('');
    const [CountiesData, setCountiesData] = useState([]);
    const [MunicipalitiesData, setMunicipalitiesData] = useState([]);
    const [AllMunicipalitiesData, setAllMunicipalitiesData] = useState([]);
    const [selectedValue, setSelectedValue] = useState('all'); // Initial value set to 'all'
    const [DistID, setDistID] = useState('');
    const [IsVisible, setIsVisible] = useState(true);
    const [AllResult, setAllResult] = useState([]);
    //   ************All legistative Data fetch***************
    useEffect(() => {
        axios.get(`${baseUrl}admin/district/getDistrictList/${districtId}`)
            .then(response => {
                const { districtList, MunicipalName, MunicipalID } = response.data;
                setLegislatorData(districtList);
                setMunicipalName(MunicipalName);
                setMunicipalID(MunicipalID);
                setSelectedValue(MunicipalID);
            })
            .catch(error => {
                console.error("Error fetching legislator data:", error);
            });
    }, [districtId]);
    // **********All Municipal Drop down data fetch*************
    useEffect(() => {
        // axios.get(`${baseUrl}/admin/getAllMunicipalities`)
        axios.get(`${baseUrl}admin/district/getAllMunicipalities`)
            .then(response => {
                setAllMunicipalitiesData(response.data);
            })
            .catch(error => {
                console.error("Error fetching legislator data:", error);
            });
    }, []);
    // **************According to the drop down value fetch the data******************
    const handleSelectChange = (event) => {
        const newValue = event.target.value;
        setDistID(newValue);
        setSelectedValue(newValue);
        if (newValue === 'all') {
            axios.get(`${baseUrl}admin/district/getAllDistrictMunicipal`)
                .then(response => {
                    const { LegistativeList, DistrictList, AllResult } = response.data;
                    setLegislatorData(LegistativeList);
                    setAllResult(AllResult);
                    // setSelectedValue('all');
                })
                .catch(error => {
                    console.error("Error fetching municipality data:", error);
                });
            setIsVisible(false);
        } else {
            axios.get(`${baseUrl}admin/district/getDistrictMunicipal/${newValue}`)
                .then(response => {
                    const { legistrativeList, MunicipalList, CountList, MunicipalID } = response.data;
                    setLegislatorData(legistrativeList);
                    setCountiesData(CountList);
                    setMunicipalitiesData(MunicipalList);
                    // setSelectedValue(MunicipalID);
                })
                .catch(error => {
                    console.error("Error fetching municipality data:", error);
                });
            setIsVisible(true);
        }
    };
    // ***************************
    useEffect(() => {
        axios.get(`${baseUrl}admin/district/getCounties/${districtId}`)
            .then(response => {
                // Set the data in state
                setCountiesData(response.data);
            })
            .catch(error => {
                console.error("Error fetching legislator data:", error);
            });
    }, [districtId]);
    // ***********************
    useEffect(() => {
        axios.get(`${baseUrl}admin/district/getDistrictList/${districtId}`)
            .then(response => {
                const { districtList, MunicipalName, MunicipalID } = response.data;
                setLegislatorData(districtList);
                setMunicipalName(MunicipalName);
                setMunicipalID(MunicipalID);
                setSelectedValue(MunicipalID);
            })
            .catch(error => {
                console.error("Error fetching legislator data:", error);
            });
    }, [districtId]);

    // ****************************

    useEffect(() => {
        // axios.get(`${baseUrl}admin/district/getMunicipalities/${districtId}`)
        axios.get(`${baseUrl}admin/district/district/getMunicipalities/${districtId}`)
            .then(response => {
                setMunicipalitiesData(response.data);
            })
            .catch(error => {
                console.error("Error fetching legislator data:", error);
            });
    }, [districtId]);

    const queryString = districtId;
    const numericPart = queryString.split('-')[1];
    return (
        <section className="bmainpart">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="listingbox">
                            <div className="headingbox">
                                <h2>District {numericPart}</h2>
                            </div>
                            <table id="example" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th>Email</th>
                                        <th>Legislator</th>
                                        <th>Party</th>
                                        <th>House</th>
                                        <th>
                                            District<br />
                                            <select name="muniList" id="muniList" value={selectedValue} onChange={handleSelectChange}>
                                                <option value="all">All</option>
                                                {AllMunicipalitiesData.map(municipal12 => (
                                                    <option value={municipal12.id}>{municipal12.MunicipalitiesName}</option>
                                                ))}
                                            </select>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {legislatorData.map(legislator => (
                                        <tr key={legislator.id}>
                                            <td><a href={`${baseUrl}mailing?district=${legislator.DistrictID}`}>
                                                <i className="fa fa-envelope"></i>
                                            </a>
                                            </td>
                                            <td><Link to={`/district/${legislator.DistrictID}/${legislator.slug}`}>{legislator.RegistrationName}</Link></td>
                                            <td>{legislator.Party}</td>
                                            <td>{legislator.House}</td>
                                            <td><Link to={`/districts/district-${legislator.DistrictID}`}>{`District ${legislator.DistrictID}`}</Link></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {IsVisible ? (
                            <div className="district-info">
                                <div className="headingbox">
                                    <h2>District Info</h2>
                                    <div className="district-info-data">
                                        <p> <strong>Counties: </strong>
                                            {CountiesData.map((counties, index) => (
                                                <React.Fragment key={counties.id}>
                                                    {counties.CountiesName}
                                                    {index !== CountiesData.length - 1 ? ', ' : ''}
                                                </React.Fragment>
                                            ))}
                                        </p>
                                        <p><strong>Municipalities: </strong>
                                            {MunicipalitiesData.map((Municipal, index) => (
                                                <React.Fragment key={Municipal.id}>
                                                    {Municipal.MunicipalitiesName}
                                                    {index !== MunicipalitiesData.length - 1 ? ', ' : ''}
                                                </React.Fragment>
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="district-info">
                                <div className="headingbox dstlistbx">
                                    <h2>District Info</h2>
                                    {AllResult.map(res => (
                                        <div className="district-info-data">
                                            <h3>{res.DistrictName}</h3>
                                            <p> <strong>Counties: </strong>{res.CountyNames}</p>
                                            <p><strong>Municipalities: </strong>{res.MuniName}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}


                    </div>
                </div>
            </div>

        </section>
    );
}

export default DistrictList;
