import { useEffect, useState } from "react"
import '../Assets/Css/Popup.css'
import { Link,useNavigate  } from "react-router-dom";
import {maillingUrl} from './utils/config'
export const Popup = () => {
    const navigate = useNavigate();
    const [alertState, setAlertState] = useState();
    useEffect(() => {
        const fn = (event) => {
            setAlertState({ assembly1: event.detail.assembly1, assembly2: event.detail.assembly2, senate: event.detail.senate, objID: event.detail.objID, DisTrictname: event.detail.DisTrictname });
        }
        window.addEventListener('popup', fn)
        return () => window.removeEventListener('popup', fn)
    }, [])
    const onPopupClose = () => {
        setAlertState(undefined);
    }
    const onPopupCloseAndNavigate = (districtId) => {
        setAlertState(undefined);
        navigate(`/districts/district-${districtId}`);
      };
    return (alertState && <div id={`popbox${alertState.objID}`} className="popboxmain"><div className="mainDiv" >
        <div className="popdetails">
            <h2> {alertState.DisTrictname} </h2>
            {/* <p><span>Assembly</span> {alertState.assembly1}</p>
            <p><span>Assembly</span> {alertState.assembly2}</p>
            <p><span>Senate</span> {alertState.senate}</p> */}
            <h5 onClick={onPopupClose}>X</h5>
            <p>Select this district</p>
            
            <Link className="dstrdtls" to={`${maillingUrl}/signup?district=${alertState.objID}`}>Confirm</Link>
            <br></br>
            {/* <Link onClick={() => onPopupCloseAndNavigate(alertState.objID)} className="dstrdtls" to={`districts/district-${alertState.objID}`}>See Details</Link> */}
        </div>
    </div></div>)
}
export const myPopup = {
    open: (assembly1, assembly2, senate, objID, DisTrictname) => {
        // console.log("Souvik");
        const selectionFired = new CustomEvent("popup", {
            "detail": { assembly1, assembly2, senate, objID, DisTrictname }
        });
        window.dispatchEvent(selectionFired);

    }
    
}