import React from 'react'
import { baseUrl,maillingUrl } from '../Component/utils/config.js'
import { Parser } from "html-to-react";


const DistrictListDetailsCard = (legislator) => {
    const legislatorData = legislator.legislator;
    return (
        <div class="col-lg-10  offset-1">
            <div className='legbox'>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="leader-bio">
                            <img src={`${baseUrl}admin/uploads/legislator/${legislatorData.featured_image}`} alt="" />
                            <h3>District Office Address:</h3>
                            
                            <p dangerouslySetInnerHTML={{ __html: legislatorData?.OfficeAddress }} />

                            {legislatorData.PhoneNumber && (
                                <p><i className='fa fa-phone'></i> <a href={`tel:${legislatorData.PhoneNumber}`}>{legislatorData.PhoneNumber}</a></p>
                            )}

                            {legislatorData.Fax && (
                                <p><i className='fa fa-fax'></i> {legislatorData.Fax}</p>
                            )}
                            {/* ?letter=districtmaps&district=1 */}
                            <a href={`${maillingUrl}?letter=districtmaps&district=${legislatorData.DistrictID}`}>
                            <i className="fa fa-envelope"></i> Contact Your Legislator
                            </a>

                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="leader-details">

                            <div className='CardHead'>
                                <p className='titleheadmn'>{legislatorData.RegistrationName}</p>
                            </div>
                            <h3>Personal and Career Information</h3>
                            {/* <p><strong>Born: </strong> {legislatorData.Born}</p> */}
                            {legislatorData.Born && (
                                <p>
                                    <strong>Born: </strong> {legislatorData.Born}
                                </p>
                            )}

                            {legislatorData.Education && (

                                <p>
                                    <strong>Education:</strong>
                                    <div dangerouslySetInnerHTML={{ __html: legislatorData.Education }} />
                                </p>
                            )}

                            {legislatorData.Occupation && (
                                <p>  <strong>Occupation:</strong>
                                    <div dangerouslySetInnerHTML={{ __html: legislatorData.Occupation }} />
                                </p>
                            )}

                            {legislatorData.POPService && (
                                <p>
                                    <h3> Public or Party Service</h3>
                                    <div dangerouslySetInnerHTML={{ __html: legislatorData.POPService }} />
                                </p>
                            )}

                            {legislatorData.LegislativeService && (
                                <p>
                                    <h3> Legislative Service</h3>
                                    <div dangerouslySetInnerHTML={{ __html: legislatorData.LegislativeService }} />
                                </p>
                            )}

                            {legislatorData.Committees && (
                                <p>
                                    <h3>Committees</h3><div dangerouslySetInnerHTML={{ __html: legislatorData.Committees }} />
                                </p>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default DistrictListDetailsCard