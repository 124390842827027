import React from 'react'
import { useParams } from "react-router-dom";
import DistrictListDetailsCard from '../Component/DistrictListDetailsCard'
import { useEffect, useState } from "react";
import axios from 'axios';
import { baseUrl,adminUrl } from '../Component/utils/config.js';
const DistrictDetailsList = () => {
    let { districtId } = useParams();
    const [legislatorData, setLegislatorData] = useState([]);
    const [IsVisible, setIsVisible] = useState(true);
    const [CountiesData, setCountiesData] = useState([]);
    const [MunicipalitiesData, setMunicipalitiesData] = useState([]);
    const [AllResult, setAllResult] = useState([]);
    useEffect(() => {

        axios.get(`${adminUrl}admin/district/getDistrictListCard/${districtId}`)
            .then(response => {
                // Set the data in state
                // const [districtList, MunicipalName, MunicipalID] = response.data;
                setLegislatorData(response.data);
            })
            .catch(error => {
                console.error("Error fetching legislator data:", error);
            });
    }, [districtId]);
    const queryString = districtId;
    const numericPart = queryString.split('-')[1];

    useEffect(() => {
        // alert(districtId);
        // alert(numericPart);

        axios.get(`${adminUrl}admin/district/getDistrictMunicipal/${numericPart}`)
            .then(response => {
                // const { legistrativeList, MunicipalList, CountList, MunicipalID } = response.data;
                const { MunicipalList, CountList, MunicipalID } = response.data;
                // setLegislatorData(legistrativeList);
                setCountiesData(CountList);
                setMunicipalitiesData(MunicipalList);
                // setSelectedValue(MunicipalID);
            })
            .catch(error => {
                console.error("Error fetching municipality data:", error);
            });
        setIsVisible(true);
    }, []);

    return (
        <section class="bmainpart">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="listingbox">
                            <div class="headingbox">
                                <h2>District {numericPart}</h2>
                            </div>
                        </div>
                    </div>
                    {/* ************************* */}
                    <div className="col-lg-12">
                        <div class="listingbox">
                            <div class="headingbox">
                            {IsVisible ? (
                                <div className="district-info">
                                    <div className="headingbox">
                                        <h2>District Info</h2>
                                        <div className="district-info-data">
                                            <p> <strong>Counties: </strong>
                                                {CountiesData.map((counties, index) => (
                                                    <React.Fragment key={counties.id}>
                                                        {counties.CountiesName}
                                                        {index !== CountiesData.length - 1 ? ', ' : ''}
                                                    </React.Fragment>
                                                ))}
                                            </p>
                                            <p><strong>Municipalities: </strong>
                                                {MunicipalitiesData.map((Municipal, index) => (
                                                    <React.Fragment key={Municipal.id}>
                                                        {Municipal.MunicipalitiesName}
                                                        {index !== MunicipalitiesData.length - 1 ? ', ' : ''}
                                                    </React.Fragment>
                                                ))}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="district-info">
                                    <div className="headingbox dstlistbx">
                                        <h2>District Info</h2>
                                        {AllResult.map(res => (
                                            <div className="district-info-data">
                                                <h3>{res.DistrictName}</h3>
                                                <p> <strong>Counties: </strong>{res.CountyNames}</p>
                                                <p><strong>Municipalities: </strong>{res.MuniName}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            </div>
                        </div>
                    </div>
                    
                    {/* ************************ */}
                    {legislatorData.map(legislator => (
                        <DistrictListDetailsCard key={legislator.id} legislator={legislator} />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default DistrictDetailsList