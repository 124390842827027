import React from 'react'

const Footer = () => {
    return (
        <>
            <section className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 mt-20">
                            <div className="foot-link">
                                <h3>How to Vote</h3>
                                <ul>
                                    <li><a href="https://getactivenj.com/how-to-vote/">How To Vote</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-3 mt-20">
                            <div className="foot-link">
                                <h3>Get Involved</h3>
                                <ul>
                                    <li><a href="https://getactivenj.com/get-election-reminders/">Get Election Reminders</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-3 mt-20">
                            <div className="foot-link">
                                <h3>Programs &amp; Resources</h3>
                                <ul>
                                    <li><a href="https://getactivenj.com/check-your-registration/">Check Your Registration</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-3 mt-20">
                            <div className="foot-link">
                                <h3>About Get Active</h3>
                                <ul>
                                    <li><a href="https://getactivenj.com/voting-faqs/">Voting Faqs</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="sec-0">
                <div className="container-fluid p-0">
                    <div className="copyrightbar">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="copyright">© COPYRIGHT 2023 Get Active <a href="https://getactivenj.com/privacy-policy">Privacy Policy</a> | <a href="https://getactivenj.com/financial">Financial</a></div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="foot-social">
                                        <ul>
                                            <li>
                                                <a href="#!"><i className="fa fa-facebook"></i></a>
                                            </li>
                                            <li>
                                                <a href="#!"><i className="fa fa-twitter"></i></a>
                                            </li>
                                            <li>
                                                <a href="#!"><i className="fa fa-youtube"></i></a>
                                            </li>
                                            <li>
                                                <a href="#!"><i className="fa fa-linkedin"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

           
           
       
        </>
    )
}

export default Footer