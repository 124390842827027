import React from 'react'
import $ from 'jquery';
import LogoImage from '../../Assets/Images/logo.png'
import { useState } from 'react';
import cros from '../../Assets/Images/closebox.png';

import { baseUrl,maillingUrl } from '../utils/config.js';
const Header = () => {
    const [IsMenu, setIsMenu] = useState(false);

    const spStyle = {
        marginTop: '-1px',
        padding: '0',
    };

    const logoSty = {
        width: '32px',
        height: '32px',
        top: '25px',
        position: 'absolute',
        right: '35px',
    };

    const MobileMenuHandle = () => {
       // $('.overlaybox').show();
    //    $(".overlaybox").animate({
    //     width: "toggle"
    //   });
    $(".overlaybox").toggle("slide");
        $('.desklogo img').hide();
        $('#menu').show();
        $('#openmenu').hide();
        $('#menu').addClass('openMenuNew');
        setIsMenu(true);
    }

    const CloseMenuBox = () => {
        //$('.overlaybox').hide();
        $(".overlaybox").toggle("slide");
        $('#menu').hide();
        $('#menu').removeClass('openMenuNew');
        $('.desklogo img').show();
        $('#openmenu').show();
        $('#chkmenu').attr('checked', false);
        setIsMenu(false);
    }

    $(window).scroll(function () {

        var windowwidth = $(window).width();
        
        if(windowwidth >=800)
        {
            var sticky = $('.mainmenubar'),
            scroll = $(window).scrollTop();

        if (scroll >= 100) sticky.addClass('fixed');
        else sticky.removeClass('fixed');
        }
        else
        {
            var sticky = $('.mainmenubar'),
            scroll = $(window).scrollTop();

            if (scroll >= 100) sticky.addClass('fixedmobile');
            else sticky.removeClass('fixedmobile');
        }
        
    });
    // alert('sanjib');
    return (
        <>

           

            <header>
                <div className="topbar">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="topmenu">
                                    <ul>
                                        <li><a href="https://www.bonfire.com/store/rockthevote/">Shop</a></li>
                                        <li><a href="https://getactivenj.com/get-election-reminders/">Get Election Reminders</a></li>
                                        <li><a href="https://getactivenj.com/contact-your-local-election-official/">Contact Your Local Election Official</a></li>
                                        <li>
                                            <a href="#!"><i className="fa fa-facebook"></i></a>
                                        </li>
                                        <li>
                                            <a href="#!"><i className="fa fa-twitter"></i></a>
                                        </li>
                                        <li>
                                            <a href="#!"><i className="fa fa-instagram"></i></a>
                                        </li>
                                        <li>
                                            <a href="#!"><i className="fa fa-youtube"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="overlaybox" onClick={CloseMenuBox}>
                    {/* <i className="fa fa-close" id="CloseBox"  ></i> */}
                    {/* <img src={`cros`} alt="" /> */}
                    <div className="row">
                        <div className="col-xs-6">
                                <div className="logobox moblogo">
                                    <a href="https://getactivenj.com/"><img src={LogoImage} alt="" /> </a>
                                </div>
                        </div>
                        <div className='col-sm-6'>
                        <img src={cros} alt="" style={logoSty}/>
                        </div>

                        <div className="col-sm-12">
                            <ul className="mobilemenul">                                             

                                <li><a href="https://getactivenj.com/register-to-vote/">Register To Vote</a></li>
                                <li><a href="https://getactivenj.com/check-your-registration/">Check Registration</a></li>
                                <li><a href="https://getactivenj.com/how-to-vote/">How To Vote</a></li>
                                <li><a href="https://getactivenj.com/voting-faqs/">FAQs</a></li>
                                <li><a href="https://getactivenj.com/map/">Map</a></li>
                                <li><a href="https://rockthevote.org/about-rock-the-vote/donate/">Donate</a></li>
                                <li>
                                    <a href={`${maillingUrl}`}><i className="fa fa-user"></i> Profile </a>
                                </li>
                                </ul>
                        </div>
                </div>
               
            </div>



                <div className={`mainmenubar`}>

             
                    {/* <div className={`mainmenubar ${IsMenu ? '' : 'fixed'}`}> */}
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 col-5 col-sm-6">
                                {/* spStyle */}
                                <div className={`logobox desklogo`} style={IsMenu ? spStyle : null}>
                                    <a href="https://getactivenj.com/"><img src={LogoImage} alt="" /> </a>
                                </div>
                            </div>
                            <div className="col-md-10 col-7 col-sm-6">
                                <div className="navbox">
                                    <ul>
                                        <li><a href="https://getactivenj.com/register-to-vote/">Register To Vote</a></li>
                                        <li><a href="https://getactivenj.com/check-your-registration/">Check Registration</a></li>
                                        <li><a href="https://getactivenj.com/how-to-vote/">How To Vote</a></li>
                                        <li><a href="https://getactivenj.com/voting-faqs/">FAQs</a></li>
                                        <li><a href="https://getactivenj.com/map/">Map</a></li>
                                        <li><a href="https://rockthevote.org/about-rock-the-vote/donate/">Donate</a></li>
                                        <li>
                                            <a href={`${maillingUrl}`}><i className="fa fa-user"></i> Profile </a>
                                        </li>

                                    </ul>
                                </div>

                                <div className='mobilemenu'>


                                    <nav role='navigation'>
                                        {/* <div id="menuToggle" onClick={MobileMenuHandle}> */}
                                        <div id="menuToggle" onClick={MobileMenuHandle}>

                                            <div className='fabarbox'>
                                                <i className='fa fa-bars'></i>
                                            </div>

                                            {/* <div className='xxx' id='openmenu' > */}

                                            {/* <input type="checkbox" id='chkmenu' />
                                        <span></span>
                                        <span></span>
                                        <span></span> */}

                                            {/* </div> */}

                                            
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header