import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { myPopup } from './Popup';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
const SixMap = () => {

    useEffect(() => {
        mapboxgl.accessToken = 'pk.eyJ1IjoiZ2V0YWN0aXZlbmoiLCJhIjoiY2xsNWhtNWJ1MGVvNTNkb3p6enBhb2lqcCJ9.eiI57yla0BXZRlvtKmQ0Gg';
        const map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/getactivenj/cllscgh78008801r7dv4022zf',

            center: [-74.381120614147804, 40.409128270198401],
            minZoom: 2,
            zoom: 7,
        });
        map.on('load', () => {
        });
        map.addControl(
            new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                countries: 'US',
                bbox: [-75.559614, 38.928524, -73.893979, 41.357423],

                filter: function (item) {
                    return item.context.some((i) => {
                        return (
                            i.id.split('.').shift() === 'region' &&
                            i.text === 'New Jersey'
                        );
                    });
                },
                mapboxgl: mapboxgl
            })
        );
        const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });
        map.on('click', (e) => {
            const clickedFeatures = map.queryRenderedFeatures(e.point);
            if (clickedFeatures.length > 0) {
                const clickedLayerId = clickedFeatures[0].layer.id;
                const clickedLayerProperties = clickedFeatures[0].properties;
                const propertiesString = JSON.stringify(clickedLayerProperties, null, 2);
                const properties = JSON.parse(propertiesString);
                if (properties.ASSEMBLY1 && properties.ASSEMBLY2 && properties.SENATE) {
                    myPopup.open(properties.ASSEMBLY1, properties.ASSEMBLY2, properties.SENATE, properties.DISTRICT, properties.DIST_LABEL);
                }
            }
        });
        map.on('click', () => {
            popup.remove();
        });

        return () => {
            map.remove();
        };
    }, []);
    const popup = new mapboxgl.Popup({
        anchor: 'bottom',
        closeButton: false,
        closeOnClick: false
    });
    return (
        <>
            <section className="bmainpart">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center searchfont">
                            <h3>Step 1: Find Your District</h3>
                            <h1></h1>
                            <h1>Search your address in the search bar in the map to get your district</h1>
                        </div>
                    </div>
                </div>
            </section>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-10 offset-1 mob-lm-0'>
                        <div id="map" style={{ top: 0, bottom: 0, width: '100%', height: '500px' }} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SixMap;
