import React from 'react'
import ProfileImage from '../Assets/Images/bucco_anthony_m_2022.jpg'
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { baseUrl } from '../Component/utils/config.js'
import axios from 'axios';
const DistrictDetails = () => {
    const { districtId, legislatorSlug } = useParams();
    const [legislatorData, setLegislatorData] = useState([]);
    useEffect(() => {
        axios.get(`${baseUrl}admin/district/getSenatorDetails/${districtId}/${legislatorSlug}`)
            .then(response => {
                // Set the data in state
                setLegislatorData(response.data);
            })
            .catch(error => {
                console.error("Error fetching legislator data:", error);
            });
    }, []);
    // http://mapbox.getactivenj.com/admin/uploads/district-details/inner-prodbanner_mob2.jpg
    // const imageURL = 'http://mapbox.getactivenj.com/admin/uploads/district-details/';
    return (
        <section class="bmainpart">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="listingbox">
                            <div class="headingbox">
                                <h2>{legislatorData.RegistrationName}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="position">
                            {legislatorData.Position ? (
                                <p>
                                    <h3>{legislatorData.Position} - <Link to={`/legislative/district-${districtId}`}>District {legislatorData.DistrictID}</Link></h3>
                                </p>
                            ) : (
                                <p>
                                    <h3> <Link to={`/legislative/district-${districtId}`}>District {legislatorData.DistrictID}</Link></h3>
                                </p>
                            )}

                        </div>
                    </div>
                    <div class="col-lg-10  offset-1">
                        <div className='legbox'>
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="leader-bio">
                                        <img src={`${baseUrl}admin/uploads/legislator/${legislatorData.featured_image}`} alt="" />
                                        <h3>District Office Address:</h3>
                                       

                                        <p dangerouslySetInnerHTML={{ __html: legislatorData?.OfficeAddress }} />
                                        
                                        {legislatorData.PhoneNumber && (
                                            // {legislatorData.PhoneNumber}
                                            <p><i className='fa fa-phone'></i> <a href={`tel:${legislatorData.PhoneNumber}`}>{legislatorData.PhoneNumber}</a></p>
                                        )}

                                        {legislatorData.Fax && (
                                            <p><i className='fa fa-fax'></i> {legislatorData.Fax}</p>
                                        )}

                                        
                                        <a href={`${baseUrl}mailing?district=${districtId}`}>
                                            <i className="fa fa-envelope"></i> Contact Your Legislator
                                        </a>
                                   

                                    </div>
                                </div>
                                <div class="col-lg-8">
                                    <div class="leader-details">
                                        <h3>Personal and Career Information</h3>
                                        {/* <p><strong>Born: </strong> {legislatorData.Born}</p> */}
                                        {legislatorData.Born && (
                                            <p>
                                                <strong>Born: </strong> {legislatorData.Born}
                                            </p>
                                        )}

                                        {legislatorData.Education && (

                                            <p>
                                                <strong>Education:</strong>
                                                <div dangerouslySetInnerHTML={{ __html: legislatorData.Education }} />
                                            </p>
                                        )}

                                        {legislatorData.Occupation && (
                                            <p>  <strong>Occupation:</strong>
                                                <div dangerouslySetInnerHTML={{ __html: legislatorData.Occupation }} />
                                            </p>
                                        )}

                                        {legislatorData.POPService && (
                                            <p>
                                                <h3> Public or Party Service</h3>
                                                <div dangerouslySetInnerHTML={{ __html: legislatorData.POPService }} />
                                            </p>
                                        )}

                                        {legislatorData.LegislativeService && (
                                            <p>
                                                <h3> Legislative Service</h3>
                                                <div dangerouslySetInnerHTML={{ __html: legislatorData.LegislativeService }} />
                                            </p>
                                        )}

                                        {legislatorData.Committees && (
                                            <p>
                                                <h3>Committees</h3><div dangerouslySetInnerHTML={{ __html: legislatorData.Committees }} />
                                            </p>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DistrictDetails